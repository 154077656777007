import React, { Fragment, useState } from "react"
import moment from "moment"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"

import Message from "elements/Message"

const Calendly = ({ callback, state }) => {
  const [successMessage, setSuccessMessage] = useState(null)

  let prefill = {
    name: `${state.firstName || ""} ${state.lastName || ""}`.trim(),
    email: state.email,
  }

  const getCalendarUrl = () => {
    switch (state?.channel?.toLowerCase()) {
      case "mobile phone call":
      case "landline phone call":
      case "viber":
      case "google meet":
      default:
        return `${
          process.env.GATSBY_CALENDLY_GMEET_BOOKING_PAGE_URL
        }?month=${moment().format("YYYY-MM")}`
    }
  }

  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
    primaryColor: "0d6d6e",
  }

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setSuccessMessage(
        <p className="is-size-6">
          Your teleconsult has been scheduled. Please wait as we redirect you to
          another page for further instructions.
        </p>
      )

      if (callback) callback(e?.data?.payload)
    },
  })

  return (
    <Fragment>
      {successMessage ? <Message color="info">{successMessage}</Message> : null}
      <InlineWidget
        url={getCalendarUrl()}
        prefill={prefill}
        pageSettings={pageSettings}
        styles={{ height: "700px" }}
      />
    </Fragment>
  )
}

export default Calendly
